<template>
  <div class="main">
    <TopMenu
        v-bind:menuList="menuList"
        v-bind:currentPage="currentPage"
    />
    <router-view
        v-bind:bio="bio"
        v-bind:messages="messages"
        v-bind:info="info"
        v-bind:videoList="videoList"
        v-bind:serviceList="serviceList"
        v-bind:langList="langList"
    />
    <FooterSpace/>
  </div>
</template>

<script>
import TopMenu from './components/TopMenu.vue'
import FooterSpace from './components/FooterSpace.vue'

let locale = window.location.pathname.replace(/^\/([^/]+).*/i, '$1');
if (locale === 'ru') {
  locale = locale ? '/' + locale : '';
} else {
  locale = '';
}

export default {
  name: 'App',
  data() {
    if (locale === '/ru') {
      return {
        langList: [
          {
            title: 'English',
            code: 'en',
            href: '/',
            titleCode: 'en',
          },
        ],
        currentPage: this.$router.currentRoute,
        bio: {
          name: 'Влад Бажанов',
          profession: 'Senior Backend Engineer',
          workExperience: this.workDuration('10/1/2015', 'ru'),
          description: '\n' +
              'За время своей карьеры я возглавлял команды и разрабатывал системы, которые эффективно масштабируются, внедряя такие технологии, как Docker, Kafka и Redis.\n' +
              '\n' +
              'Одно из моих ключевых достижений — оптимизация процессов разработки. Мне удалось сократить время настройки среды с нескольких дней до минут, а также повысить производительность отдельных систем в 10 раз. Я всегда стремлюсь к созданию надёжных и поддерживаемых решений, которые делают процессы быстрее и проще. Мне нравится делиться знаниями, работать в команде и постоянно учиться новым технологиям, чтобы идти в ногу с динамично развивающимся миром IT.\n' +
              '\n' +
              'Я увлечён своей работой и всегда готов к новым вызовам!',
          links: [
            {
              title: 'Telegram',
              icon: 'telegram',
              href: 'https://t.me/vlads'
            },
            {
              title: 'Github',
              icon: 'github',
              href: 'https://github.com/vladsdev'
            },
            {
              title: 'LinkedIn',
              icon: 'linkedin-square',
              href: 'https://linkedin.com/in/vlad-bazhanov'
            },
            {
              title: 'Instagram',
              icon: 'instagram',
              href: 'https://instagram.com/vlads.pro'
            },
          ]
        },
        messages: {
          'WorkExperience': 'Опыт работы',
          'Skills': 'Навыки',
          'Languages': 'Языки',
          'Education': 'Образование',
          'Videos': 'Видео',
        },
        menuList: [
          {id: 1, title: 'Главная', url: locale !== '' ? locale + '/' : '/', icon: 'vcard'},
          {id: 2, title: 'Информация', url: locale + '/info', icon: 'info'},
          {id: 3, title: 'Публичность', url: locale + '/pub', icon: 'newspaper-o'},
          {id: 4, title: 'Услуги', url: locale + '/service', icon: 'usd'},
        ],
        info: {
          lang: [
            {title: 'Русский (носитель)'},
            {title: 'Английский (Intermediate)'},
          ],
          skills: [
            {title: 'PHP'},
            {title: 'MySQL'},
            {title: 'PostgreSQL'},
            {title: 'Laravel'},
            {title: 'Python'},
            {title: 'Kafka'},
            {title: 'RabbitMQ'},
            {title: 'Redis'},
            {title: 'S3'},
            {title: 'Docker'},
            {title: 'Kubernetes'},
            {title: 'Git'},
            {title: 'Gitlab CI/CD'},
            {title: 'Terraform'},
            {title: 'ELK'},
            {title: 'Bash'},
            {title: 'ClickHouse'},
            {title: 'TeamCity'},
            {title: 'Vault'},
            {title: 'TypeScript'},
            {title: 'Java'},
            {title: 'JS'},
            {title: 'Vue.js'},
            {title: 'PHPUnit'},
            {title: 'Bitrix24'},
            {title: 'Swift'},
          ],
          workHistory: [
            /*{
              title: 'Engineer',
              dateFrom: 'Март 2025',
              dateTo: 'Настоящее время',
              dateDuration: this.workDuration('1/03/2025', 'ru'),
              descList: [],
              skills: [
                {title: 'PHP'},
              ],
            },*/
            {
              title: 'Senior Backend Engineer',
              short: 'Avito',
              dateFrom: 'Октябрь 2022',
              dateTo: 'Март 2025',
              dateDuration: '2 года 5 месяцев',
              descList: [
                {id: 0, value: 'Технически руководил командой из 10 разработчиков'},
                {
                  id: 1,
                  value: 'Интегрировал Docker для локальной разработки, сократив время развёртывания среды с 2 дней до 30 минут'
                },
                {
                  id: 2,
                  value: 'Создал и внедрил стандарты кодирования и документацию, что повысило качество кода и скорость разработки на 20%'
                },
                {id: 3, value: 'Интегрировал Kafka в ключевые компоненты системы, что ускорило их работу на 200–1000%'},
                {
                  id: 4,
                  value: 'Перевёл проект на кластер Redis, что позволило более гибко масштабироваться и увеличить объём кэша на 300%+'
                },
                {
                  id: 5,
                  value: 'Автоматизировал миграции (схемы БД, сборка JS, копирование файлов), что позволило команде сэкономить до 15% времени'
                },
                {id: 6, value: 'Внедрил лучшие практики разработки (Чистая архитектура, ELK, Docker, Kafka)'}
              ],
              skills: [
                {title: 'PHP'},
                {title: 'MySQL'},
                {title: 'Kafka'},
                {title: 'Redis'},
                {title: 'PostgreSQL'},
                {title: 'JS'},
                {title: 'ELK'},
                {title: 'S3'},
                {title: 'Kubernetes'},
                {title: 'Docker'},
                {title: 'Bash'},
                {title: 'TeamCity'},
                {title: 'PHPUnit'},
                {title: 'Java'},
              ],
            },
            {
              title: 'Backend Engineer',
              short: 'Bitrix24',
              dateFrom: 'Февраль 2019',
              dateTo: 'Сентябрь 2022',
              dateDuration: '3 года 7 месяцев',
              descList: [
                {
                  id: 0,
                  value: 'Разработал новую версию Rest API для миллионов пользователей'
                },
                {
                  id: 1,
                  value: 'Создал онлайн-конструктор Rest-запросов с автогенерацией кода, что снизило порог входа на 30%'
                },
                {
                  id: 2,
                  value: 'Создал приложение с механизмом zero-coding, позволив клиентам и интеграторам быстро настраивать CRM-системы и продавать их на маркете'
                },
                {id: 3, value: 'Поддерживал высоконагруженные OAuth серверы (1042 RPS)'},
                {
                  id: 4,
                  value: 'Разработал систему экспорта/импорта настроек продуктов для продажи готовых решений на маркетплейсе'
                }
              ],
              skills: [
                {title: 'PHP'},
                {title: 'JS'},
                {title: 'MySQL'},
                {title: 'Bitrix Framework'},
                {title: 'Rest API'},
                {title: 'Python'},
                {title: 'ClickHouse'},
                {title: 'PHPUnit'},
                {title: 'RabbitMQ'},
                {title: 'S3'},
                {title: 'HTML'},
                {title: 'CSS'},
              ],
            },
            {
              title: 'PHP-Разработчик',
              short: 'Фриланс',
              dateFrom: 'Октябрь 2015',
              dateTo: 'Февраль 2019',
              dateDuration: '3 года 4 месяца',
              descList: [
                {id: 0, value: 'Создал сайты-визитки, интернет-магазины и личные кабинеты клиентов'},
                {id: 1, value: 'Разработал интеграции с внешними системами'},
                {
                  id: 2,
                  value: 'Автоматизировал процессы для юридической фирмы со штатом 150 человек, что сократило время на одного клиента с 2 часов до 5 минут'
                },
                {id: 3, value: 'Интегрировал Bitrix24 через Rest API'},
                {
                  id: 4,
                  value: 'Разработал механизм генерации docx документов на основе шаблонов с якорями и данных из базы данных'
                }
              ],
              skills: [
                {title: 'Bitrix Framework'},
                {title: 'PHP'},
                {title: 'JS'},
                {title: 'MySQL'}
              ],
            },
            {
              title: 'Технический специалист',
              short: 'Томский государственный университет · по совместительству',
              dateFrom: 'Август 2015',
              dateTo: 'Февраль 2019',
              dateDuration: '3 года 6 месяцев',
              descList: [
                {id: 0, value: 'Настроил и поддерживал оборудование корпуса'},
                {id: 1, value: 'Оказывал техническую поддержку на конференциях'},
                {id: 2, value: 'Консультировал сотрудников'}
              ],
            },
          ],
          educationHistory: [
            {
              title: 'Физико-технический факультет',
              dateFrom: 'Сентябрь 2013',
              dateTo: 'Май 2019',
              dateDuration: '6 лет',
              short: 'Томский государственный университет ',
            },
            {
              title: 'PHP Developer. Professional',
              dateFrom: 'Октябрь 2022',
              dateTo: 'Апрель 2023',
              dateDuration: '5 месяцев',
              short: 'OTUS',
              descList: [
                {id: 0, value: 'Изучил внутреннее устройство PHP'},
                {id: 1, value: 'Освоил Composer для управления зависимостями'},
                {id: 2, value: 'Разобрался в сетях, протоколах, балансировке и безопасности'},
                {id: 1, value: 'Углублённо изучил базы данных (PostgreSQL, Redis, MySQL)'},
                {id: 1, value: 'Применял лучшие практики в архитектуре кода и дизайне'},
                {id: 1, value: 'Использовал Unit-тестирование и профилирование кода для оптимизации'}
              ],
            },
            {
              title: 'Базы данных',
              dateFrom: 'Август 2023',
              dateTo: 'Апрель 2024',
              dateDuration: '6 месяцев',
              short: 'OTUS',
              descList: [
                {id: 0, value: 'Полный курс по работе с реляционными и нереляционными базами данных'},
                {
                  id: 1,
                  value: 'Сущности и связи (Проектирование БД, Компоненты современной СУБД, Проблемы миграции, Теорема CAP, проектирование)'
                },
                {id: 2, value: 'PostgreSQL'},
                {id: 2, value: 'MySQL'},
                {id: 2, value: 'NoSQL (Redis, Tarantool, MongoDB, Cassandra, Clickhouse)'},
              ],
            },
          ],
          /*  ikigai: {
              'love': 'you'
            },*/
        },
        serviceList: [
          {
            type: 'hot-offer',
            title: 'Аудит производительности системы',
            price: 'от 200 000 ₽',
            description: 'Комплексный аудит производительности вашей системы, выявление узких мест, рекомендации по их устранению, оптимизации базы данных и инфраструктуры.',
            options: [
              'Поддержка после аудита',
              '5 консультаций по улучшению производительности',
              'Разработка плана по оптимизации'
            ]
          },
          {
            type: 'premium',
            title: 'Автоматизация CI/CD',
            price: 'от 150 000 ₽',
            description: 'Автоматизация процесса разработки, сборки и деплоя с помощью GitLab CI/CD и Docker.',
            options: [
              'Аудит инфраструктуры',
              'Настройка GitLab CI/CD',
              'Обучение команды',
              'Поддержка и сопровождение'
            ]
          },
          {
            type: '',
            title: 'Контейнеризация приложения',
            price: 'от 150 000 ₽',
            description: 'Провожу контейнеризацию вашего приложения с использованием Docker и Kubernetes для создания масштабируемых и стабильных сред разработки и продакшн.',
            options: [
              'Разработка Dockerfile для приложения',
              'Создание и настройка контейнеров для микросервисов',
              'Организация оркестрации через Kubernetes',
              'Интеграция с CI/CD для автоматического деплоя',
              'Документация и поддержка'
            ]
          },
          {
            type: '',
            title: 'Интеграция Kafka и Redis',
            price: 'от 160 000 ₽',
            description: 'Интеграция систем с использованием Kafka и Redis для оптимизации производительности, масштабирования и повышения отказоустойчивости.',
            options: [
              'Поддержка и консультации на 1 месяц',
              'Настройка Kafka и Redis',
              'Документация и обучение команды'
            ]
          },
          {
            type: 'training',
            title: 'Обучение PHP',
            price: 'от 80 000 ₽',
            description: 'Обучение PHP разработке два раза в неделю по 2 часа. Программа подойдёт как новичкам, так и опытным разработчикам. Возможность обучения персонально или в группах.',
            options: [
              'Индивидуальное обучение или в небольших группах',
              'Программа для любого уровня — от начинающего до продвинутого',
              'Практические задания и обратная связь',
              'Гибкий график занятий и поддержка'
            ]
          },
          {
            type: 'training',
            title: 'Обучение разработчиков работе с Bitrix24',
            price: 'от 120 000 ₽',
            description: 'Провожу обучение по разработке и интеграции в Bitrix24. Помогаю освоить работу с Rest API, созданием приложений и разработкой модулей для коробочной версии Bitrix24.',
            options: [
              'Комплексное обучение работе с Rest API',
              'Комплексное обучение доработкам коробочной версии',
              'Наставничество'
            ]
          }
        ],
        videoList: [
          {
            'title': 'Bitrix Talks TECH. Разбор механизма событий REST.',
            'date': '2022-04-28',
            'youtubeCode': '3WSH9kJl-4c',
          },
          {
            'title': 'Разработка REST-приложений. Полезное.',
            'date': '2021-06-30',
            'youtubeCode': 'UiGgSn7_igg',
          },
          {
            'title': 'Архитектура rest-приложений для высоких нагрузок.',
            'date': '2020-04-09',
            'youtubeCode': 'lgdoJJpRFDI',
          },
          {
            'title': 'Как создавать отраслевые CRM в Битрикс24 без разработки?',
            'date': '2020-02-14',
            'youtubeCode': '50jUqm45jek',
          },
        ],
      }
    }

    return {
      langList: [
        {
          title: 'Русский',
          code: 'ru',
          href: '/ru/',
          titleCode: 'ru',
        },
      ],
      currentPage: this.$router.currentRoute,
      bio: {
        name: 'Vlad Bazhanov',
        profession: 'Senior Backend Engineer',
        workExperience: this.workDuration('10/1/2015', 'en'),
        description: '\n' +
            'Throughout my career, I’ve had the privilege of leading teams and building systems that scale efficiently, integrating technologies such as Docker, Kafka, and Redis.\n' +
            '\n' +
            'One of my key achievements is improving development workflows by introducing automation and best practices. From reducing setup time from days to minutes, to boosting system performance by 10x in certain areas — my focus is always on delivering robust and maintainable solutions. I enjoy collaborating with teams, sharing knowledge, and continuously learning about new technologies to stay ahead in the rapidly evolving tech world.\n' +
            '\n' +
            'I’m passionate about what I do and always ready for the next challenge!',
        links: [
          {
            title: 'Telegram',
            icon: 'telegram',
            href: 'https://t.me/vlads'
          },
          {
            title: 'Github',
            icon: 'github',
            href: 'https://github.com/vladsdev'
          },
          {
            title: 'LinkedIn',
            icon: 'linkedin-square',
            href: 'https://linkedin.com/in/vlad-bazhanov/'
          },
          {
            title: 'Instagram',
            icon: 'instagram',
            href: 'https://instagram.com/vlads.pro'
          },
        ]
      },
      messages: {
        'WorkExperience': 'Working experience',
        'Skills': 'Skills',
        'Languages': 'Languages',
        'Education': 'Education',
        'Videos': 'Videos',
      },
      menuList: [
        {id: 1, title: 'Home', url: locale !== '' ? locale + '/' : '/', icon: 'vcard'},
        {id: 2, title: 'Information', url: locale + '/info', icon: 'info'},
        {id: 3, title: 'Publication', url: locale + '/pub', icon: 'newspaper-o'},
      ],
      info: {
        lang: [
          {title: 'Russian (native)'},
          {title: 'English (Intermediate)'},
        ],
        skills: [
          {title: 'PHP'},
          {title: 'MySQL'},
          {title: 'PostgreSQL'},
          {title: 'Laravel'},
          {title: 'Python'},
          {title: 'Kafka'},
          {title: 'RabbitMQ'},
          {title: 'Redis'},
          {title: 'S3'},
          {title: 'Docker'},
          {title: 'Kubernetes'},
          {title: 'Git'},
          {title: 'Gitlab CI/CD'},
          {title: 'Terraform'},
          {title: 'ELK'},
          {title: 'Bash'},
          {title: 'ClickHouse'},
          {title: 'TeamCity'},
          {title: 'Vault'},
          {title: 'TypeScript'},
          {title: 'Java'},
          {title: 'JS'},
          {title: 'Vue.js'},
          {title: 'PHPUnit'},
          {title: 'Bitrix24'},
          {title: 'Swift'},
        ],
        workHistory: [
         /* {
            title: 'Engineer',
            short: 'Full-time',
            dateFrom: 'Mar 2025',
            dateTo: 'Present',
            dateDuration: this.workDuration('1/03/2025', 'ru'),
            descList: [],
            skills: [
              {title: 'PHP'},
            ],
          },*/
          {
            title: 'Senior Backend Engineer',
            short: 'Avito · Full-time',
            dateFrom: 'Oct 2022',
            dateTo: 'Mar 2025',
            dateDuration: '2 years 5 months',
            descList: [
              {
                id: 0,
                value: 'Technically led a team of 10 developers'
              },
              {
                id: 1,
                value: 'Integrated Docker for local development, reducing the environment setup time from 2 days to 30 minutes'
              },
              {
                id: 2,
                value: 'Developed and implemented coding standards and documentation, improving code quality and speed of delivery by 20%'
              },
              {
                id: 3,
                value: 'Integrated Kafka into key parts of the system, accelerating them by 200–1000% (2–10 times)'
              },
              {
                id: 4,
                value: 'Migrated the project to a Redis cluster, enabling more flexible scaling and working with significantly larger cache volumes by 300%+'
              },
              {
                id: 5,
                value: 'Automated migrations (DB schema, JS build, file copying), saving the team up to 15% of their time'
              },
              {id: 6, value: 'Implemented best development practices (Clean Architecture, ELK, Docker, Kafka)'}
            ],
            skills: [
              {title: 'PHP'},
              {title: 'MySQL'},
              {title: 'Kafka'},
              {title: 'Redis'},
              {title: 'PostgreSQL'},
              {title: 'JS'},
              {title: 'ELK'},
              {title: 'S3'},
              {title: 'Kubernetes'},
              {title: 'Docker'},
              {title: 'Bash'},
              {title: 'TeamCity'},
              {title: 'PHPUnit'},
              {title: 'Java'},
            ],
          },
          {
            title: 'Backend Engineer',
            short: 'Bitrix24 · Full-time',
            dateFrom: 'Feb 2019',
            dateTo: 'Sep 2022',
            dateDuration: '3 years 7 months',
            descList: [
              {
                id: 0,
                value: 'Developed a new version of Rest API for millions of users'
              },
              {
                id: 1,
                value: 'Created an online Rest query constructor with code generation, lowering the integration entry barrier by 30%'
              },
              {
                id: 2,
                value: 'Developed a zero-coding application that allowed clients and integrators to quickly configure and sell CRM systems on the marketplace'
              },
              {id: 3, value: 'Supported highload OAuth servers (1042 RPS), reducing authorization errors by 40%'},
              {
                id: 4,
                value: 'Developed a system for exporting/importing product configurations for selling ready-made solutions on the marketplace'
              }
            ],
            skills: [
              {title: 'PHP'},
              {title: 'JS'},
              {title: 'MySQL'},
              {title: 'Bitrix Framework'},
              {title: 'Rest API'},
              {title: 'Python'},
              {title: 'ClickHouse'},
              {title: 'PHPUnit'},
              {title: 'RabbitMQ'},
              {title: 'S3'},
              {title: 'HTML'},
              {title: 'CSS'},
            ],
          },
          {
            title: 'PHP Developer',
            short: 'Freelance worker · Freelance',
            dateFrom: 'Oct 2015',
            dateTo: 'Feb 2019',
            dateDuration: '3 years 4 months',
            descList: [
              {id: 0, value: 'Created landing pages, e-commerce sites, clients’ personal online cabinets'},
              {id: 1, value: 'Developed integrations with external systems'},
              {
                id: 2,
                value: 'Automated processes for a law firm with 150 employees, reducing time spent per client from 2 hours to 5 minutes'
              },
              {id: 3, value: 'Integrated Bitrix24 via Rest API'},
              {
                id: 4,
                value: 'Developed a document generator for creating docx files using templates with anchor tags and data from a database'
              }
            ],
            skills: [
              {title: 'Bitrix Framework'},
              {title: 'PHP'},
              {title: 'JS'},
              {title: 'MySQL'},
            ],
          },
          {
            title: 'Technical Specialist',
            short: 'National Research Tomsk State University · Part-time',
            dateFrom: 'Aug 2015',
            dateTo: 'Feb 2019',
            dateDuration: '3 years 6 months',
            descList: [
              {id: 0, value: 'Set up and supported equipment for the faculty'},
              {id: 1, value: 'Provided technical support for university conferences'},
            ],
          },
        ],
        educationHistory: [
          {
            title: 'Physics and Technology Faculty',
            dateFrom: 'Sep 2013',
            dateTo: 'May 2019',
            dateDuration: '6 years',
            short: 'National Research Tomsk State University',
          },
          {
            title: 'PHP Developer. Professional',
            dateFrom: 'Oct 2022',
            dateTo: 'Apr 2023',
            dateDuration: '5 months',
            short: 'OTUS',
            descList: [
              {id: 0, value: 'Studied the internal structure of PHP'},
              {id: 1, value: 'Mastered Composer for dependency management'},
              {id: 2, value: 'Learned about networks, protocols, load balancing, and security'},
              {id: 1, value: 'Deep knowledge of databases (PostgreSQL, Redis, MySQL)'},
              {id: 1, value: 'Applied best practices in code architecture and design'},
              {id: 1, value: 'Used Unit testing and profiling for optimization'}
            ],
          },
          {
            title: 'Databases',
            dateFrom: 'Aug 2023',
            dateTo: 'Apr 2024',
            dateDuration: '6 months',
            short: 'OTUS',
            descList: [
              {id: 0, value: 'Full course on working with relational and non-relational databases'},
              {
                id: 1,
                value: 'Entities and relationships (DB design, modern DBMS components, migration issues, CAP theorem, and DB engineering)'
              },
              {id: 2, value: 'PostgreSQL'},
              {id: 2, value: 'MySQL'},
              {id: 2, value: 'NoSQL (Redis, Tarantool, MongoDB, Cassandra, Clickhouse)'},
            ],
          },
        ],
        /*  ikigai: {
            'love': 'you'
          },*/
      },
      videoList: [
        {
          'title': 'Bitrix Talks TECH. Understanding the REST Event Mechanism.',
          'date': '2022-04-28',
          'youtubeCode': '3WSH9kJl-4c',
        },
        {
          'title': 'Development of REST applications. Useful.',
          'date': '2021-06-30',
          'youtubeCode': 'UiGgSn7_igg',
        },
        {
          'title': 'High Load Rest Application Architecture.',
          'date': '2020-04-09',
          'youtubeCode': 'lgdoJJpRFDI',
        },
        {
          'title': 'How to Create Vertical CRM in Bitrix24 Without Development.',
          'date': '2020-02-14',
          'youtubeCode': '50jUqm45jek',
        },
      ],
    }
  },
  methods: {
    workDuration(dateStart, locale = 'en') {
      const startDate = new Date(dateStart);
      const currentDate = new Date();

      let years = currentDate.getFullYear() - startDate.getFullYear();
      let months = currentDate.getMonth() - startDate.getMonth();

      if (months < 0) {
        years--;
        months += 12;
      }

      const declension = (number, words) => {
        return number % 10 === 1 && number % 100 !== 11
            ? words[0]
            : number % 10 >= 2 && number % 10 <= 4 && (number % 100 < 10 || number % 100 >= 20)
                ? words[1]
                : words[2];
      };

      let duration = '';

      if (locale === 'ru') {
        if (years > 0) {
          const yearWord = declension(years, ['год', 'года', 'лет']);
          duration += `${years} ${yearWord}`;
        }
        if (months > 0) {
          const monthWord = declension(months, ['месяц', 'месяца', 'месяцев']);
          duration += ` ${months} ${monthWord}`;
        }
      } else {
        if (years > 0) {
          const yearWord = years === 1 ? 'year' : 'years';
          duration += `${years} ${yearWord}`;
        }
        if (months > 0) {
          const monthWord = months === 1 ? 'month' : 'months';
          duration += ` ${months} ${monthWord}`;
        }
      }

      return duration.trim();
    },
  },
  components: {
    TopMenu,
    FooterSpace,
  },
}
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,300italic,400italic");
@import 'assets/css/font-awesome.min.css';
@import 'assets/css/style.css';
</style>
